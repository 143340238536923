import {
  RETRIEVE_BANNER,
  CREATE_BANNER,
  UPDATE_BANNER,
  DELETE_BANNER,
  RESTORE_BANNER,
} from "./types";

import BannerService from "../../redux/services/banner.service";

export const retrieveBanners = (param) => async (dispatch) => {
  try {
    const res = await BannerService.getAll(param);
    dispatch({
      type: RETRIEVE_BANNER,
      payload: res.data.listPages,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
    console.log(err);
  }
};


export const updateBanner = (id, data, param) => async (dispatch) => {
  try {
    const res = await BannerService.update(id, data, param);

    dispatch({
      type: UPDATE_BANNER,
      payload: res.data.bannerUrl,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteBanner = (id, param) => async (dispatch) => {
  try {
    const res = await BannerService.deleteBanner(id, param);

    dispatch({
      type: DELETE_BANNER,
      payload: res.data.listPages,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const restoreBanner = (id) => async (dispatch) => {
  try {
    const res = await BannerService.restore(id);

    dispatch({
      type: RESTORE_BANNER,
      payload: res.data.listPages,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};