import { RETRIEVE_FIRST_USER ,RETRIEVE_ASSOCIATED_USER , RETRIEVE_CHATS} from "../actions/types";

const initialState = {
    firstUser: [],
    associatedUser: [],
    roomId:[],
    chats:[]
};

const chatReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case RETRIEVE_FIRST_USER:
            return {
                ...state,
                firstUser: payload,
            };
        case RETRIEVE_ASSOCIATED_USER:
            return {
                ...state,
                associatedUser: payload,
            };
        case RETRIEVE_CHATS:
            return {
                ...state,
                chats: payload,
            };
        default:
            return state;
    }
};

export default chatReducer;
