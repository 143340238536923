import { RETRIEVE_SUPPORT_REQUEST } from "../actions/types";

const initialState = {
    contactRequests: [],
    totalcontactRequests: 0,
};

const supportReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case RETRIEVE_SUPPORT_REQUEST:
            return {
                contactRequests: payload.rows,
                totalcontactRequests: payload.count,
            };
        default:
            return state;
    }
};

export default supportReducer;
