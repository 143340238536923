import axios from "axios";
import { authHeader } from "./auth-header";
import { logout } from "../actions/auth";
import { store } from "../store";

const axiosInstanceAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // Ensure cookies are sent
  headers: authHeader(), // Set default headers
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
const refreshToken = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "user/renewSession",
      {
        withCredentials: true, // Ensure cookies are sent
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to refresh token");
    }

    // Process the queue of failed requests after refreshing the token
    processQueue(null, response.data);

    return response.data; // Adjust this based on your API response structure
  } catch (error) {
    processQueue(error, null);
    store.dispatch(logout());
    window.location.href = "/login";
    throw error;
  }
};

axiosInstanceAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { response: { status } = {} } = error;

    if (status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axiosInstanceAuth(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return refreshToken()
        .then((token) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          return axiosInstanceAuth(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    }

    return Promise.reject(error);
  }
);

const axiosInstance = {
  Auth: axiosInstanceAuth,
};

export default axiosInstance;
