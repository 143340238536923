// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview_previewContainer__GNujU img {
  padding-top: 10px;
}

.preview_deleteIcon__WHugQ {
  cursor: pointer;
  position: absolute;
  color: #000;
  left: 86px;
  height: 18px;
  margin-top: 10px;
  background-color: #D3D3D3;
}`, "",{"version":3,"sources":["webpack://./src/assets/preview.module.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;AAAJ","sourcesContent":["\r\n.previewContainer img {\r\n    padding-top: 10px;\r\n}\r\n\r\n.deleteIcon{\r\n    cursor: pointer;\r\n    position: absolute;\r\n    color: #000;\r\n    left: 86px;\r\n    height: 18px;\r\n    margin-top: 10px;\r\n    background-color: #D3D3D3;\r\n}\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"previewContainer": `preview_previewContainer__GNujU`,
	"deleteIcon": `preview_deleteIcon__WHugQ`
};
export default ___CSS_LOADER_EXPORT___;
