
import {
  RETRIEVE_FAQ,
  DELETE_FAQ,
  RESTORE_FAQ,
  UPDATE_FAQ_STATUS,
} from "./types";

import FaqService from "../services/faq.service";

export const retrieveFaqs = (param) => async (dispatch) => {
  try {
    const res = await FaqService.getAll(param);
    dispatch({
      type: RETRIEVE_FAQ,
      payload: res.data.listFAQ,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
    console.log(err);
  }
};

export const deleteFaq = (id, param) => async (dispatch) => {
  try {
    const res = await FaqService.deleteFaq(id, param);

    dispatch({
      type: DELETE_FAQ,
      payload: res.data.listFAQ,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}


export const restoreFaq = (id, param) => async (dispatch) => {
  try {
    const res = await FaqService.restore(id, param);

    dispatch({
      type: RESTORE_FAQ,
      payload: res.data.listFAQ,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}


export const activeStatusFaq = (id, Active, param) => async (dispatch) => {
  try {
    const res = await FaqService.statusUpdate(id, Active, param);

    dispatch({
      type: UPDATE_FAQ_STATUS,
      payload: res.data.listFAQ,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
