import { RETRIEVE_SERVICE_CHARGE, CREATE_SERVICE_CHARGE } from "../actions/types";

const initialState = { charges: [], totalChargesCount: 0 };

const ServiceChargeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_SERVICE_CHARGE:
      return {
        charges: payload.rows,
        totalChargesCount: payload.count,
      };

    default:
      return state;
  }
};

export default ServiceChargeReducer;
