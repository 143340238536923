
export const AdminNav = [
  {
    icon: "pe-7s-graph2",
    label: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: "pe-7s-users",
    label: "Users",
    content: [
      {
        label: "Add",
        to: "/user/add",
      },
      {
        label: "List",
        to: "/user/list",
      },
    ],
  },
  // {
  //   icon: "pe-7s-shopbag",
  //   label: "Seller",
  //   to: "/seller/list",
  // },

  {
    icon: "pe-7s-cart",
    label: "Orders",
    to: "/order/purchaselist",
  },

  {
    icon: "pe-7s-display2",
    label: "Games Ads",
    content: [
      {
        label: "Add",
        to: "/games/add",
      },
      {
        label: "List",
        to: "/games/list",
      },
    ],
  },
  // {
  //   icon: "pe-7s-help2",
  //   label: "Dispute",
  //   content: [
  //     {
  //       label: "Add",
  //       to: "/dispute/add",
  //     },
  //     {
  //       label: "List",
  //       to: "/dispute/list",
  //     },
  //   ],
  // },
  {
    icon: "pe-7s-network",
    label: "Role Permission",
    content: [
      {
        label: "Roles",
        to: "/role/list",
      },
      {
        label: "Permission",
        to: "/role/permission",
      },
    ],
  },
  {
    icon: "pe-7s-display2",
    label: "Rules and Regulations",
    content: [
      {
        label: "Add",
        to: "/rules/add",
      },
      {
        label: "List",
        to: "/rules/list",
      },
    ],
  },
  {
    icon: "pe-7s-comment",
    label: "FAQ",
    content: [
      {
        label: "Add",
        to: "/faq/add",
      },
      {
        label: "List",
        to: "/faq/list",
      },
    ],
  },
  // {
  //   icon: "pe-7s-photo",
  //   label: "Banner",
  //   content: [
  //     {
  //       label: "Add",
  //       to: "/banner/add",
  //     },
  //     {
  //       label: "List",
  //       to: "/banner/list",
  //     },
  //   ],
  // },
  {
    icon: "pe-7s-note2",
    label: "Insurance",
    content: [
      {
        label: "Add",
        to: "/insurance/add",
      },
      {
        label: "List",
        to: "/insurance/list",
      },
    ],
  },

  {
    icon: "pe-7s-browser",
    label: "CMS",
    content: [
      {
        label: "Add",
        to: "/cms/add",
      },
      {
        label: "List",
        to: "/cms/list",
      },
    ],
  },
  // {
  //   icon: "pe-7s-rocket",
  //   label: "Booster Plan",
  //   content: [
  //     {
  //       label: "Add",
  //       to: "/booster/add",
  //     },
  //     {
  //       label: "List",
  //       to: "/booster/list",
  //     },
  //   ],
  // },
  {
    icon: "pe-7s-monitor",
    label: "Contact Requests",
    to: "/contact/list",
  },
  {
    icon: "pe-7s-chat",
    label: "Manage Chat",
    to: "/chat/list",
  },
  {
    icon: "pe-7s-cash",
    label: "Manage Charge",
    content: [
      {
        label: "Add",
        to: "/charge/add",
      },
      {
        label: "List",
        to: "/charge/list",    
      },
    ],

  },
];


const getNavItem = (moduleId) => {
  switch (moduleId) {
    case 1: return ({
      icon: "pe-7s-users",
      label: "Users",
      content: [
        {
          label: "Add",
          to: "/user/add",
        },
        {
          label: "List",
          to: "/user/list",
        },
      ],
    })

    case 8: return {
      icon: "pe-7s-display2",
      label: "Games Ads",
      content: [
        {
          label: "Add",
          to: "/games/add",
        },
        {
          label: "List",
          to: "/games/list",
        },
      ],
    }

    case 19: return {
      icon: "pe-7s-display2",
      label: "Rules and Regulations",
      content: [
        {
          label: "Add",
          to: "/rules/add",
        },
        {
          label: "List",
          to: "/rules/list",
        },
      ],
    }

    case 5: return {
      icon: "pe-7s-comment",
      label: "FAQ",
      content: [
        {
          label: "Add",
          to: "/faq/add",
        },
        {
          label: "List",
          to: "/faq/list",
        },
      ],
    }

    // case 18: return {
    //   icon: "pe-7s-comment",
    //   label: "Banner",
    //   content: [
    //     {
    //       label: "Add",
    //       to: "/banner/add",
    //     },
    //     {
    //       label: "List",
    //       to: "/banner/list",
    //     },
    //   ],
    // }

    // case 17: return {
    //   icon: "pe-7s-comment",
    //   label: "Insurance Plan",
    //   content: [
    //     {
    //       label: "Add",
    //       to: "/insurance/add",
    //     },
    //     {
    //       label: "List",
    //       to: "/insurance/list",
    //     },
    //   ],
    // }

    case 21: return {
      icon: "pe-7s-cart",
      label: "Orders",
      to: "/order/purchaselist",
    }

    case 12: return {
      icon: "pe-7s-network",
      label: "Role Permission",
      content: [
        {
          label: "Roles",
          to: "/role/list",
        },
        {
          label: "Permission",
          to: "/role/permission",
        },
      ],
    }

    // case 22: return {
    //   icon: "pe-7s-rocket",
    //   label: "Booster Plan",
    //   content: [
    //     {
    //       label: "Add",
    //       to: "/booster/add",
    //     },
    //     {
    //       label: "List",
    //       to: "/booster/list",
    //     },
    //   ],
    // }

    case 10: return {
      icon: "pe-7s-cart",
      label: "Orders",
      content: [
        {
          label: "Sell",
          to: "/order/add",
        },
        {
          label: "Purchase",
          to: "/games/list",
        },
      ],
    }

    case 26: return {
      icon: "pe-7s-monitor",
      label: "Contact Requests",
      content: [
        {
          label: "List",
          to: "/contact/list",
        },
      ],
    }

    default: return null;
  }

}

// Handler for Creating the Side Navbar Based on Provided Permissions
export const createNavItems = (permissions) => {
  if (!Array.isArray(permissions)) return [];

  return permissions.reduce((navItems, { moduleId, read, create }) => {
    if (read !== 1) return navItems;

    const navItem = getNavItem(moduleId);
    if (!navItem) return navItems;

    if (create === 0 && navItem.content) {
      navItem.content = navItem.content.filter(item => item.label !== "Add");
    }

    navItems.push(navItem);
    return navItems;
  }, []);
};