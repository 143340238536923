import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

function ErrorFallback({ error, resetErrorBoundary }) {  
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function AppErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset any state that caused the error here
      }}
      onError={(error, info) => {
        // Log the error or send it to a monitoring service
        console.error('Error caught by ErrorBoundary:', error, info);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export default AppErrorBoundary;
