export const authHeader = () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: `Bearer ${token}`,
    // 'x-access-token': token,
  };
  return headers;
};

export const multipartHeader = () => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  };
  return headers;
};

export default { authHeader, multipartHeader };
