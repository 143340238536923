import axios from "axios";
import { Navigate } from "react-router-dom";

const login = (email, password, rememberMe) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "admin/login", {
      email,
      password,
    }, {
      withCredentials: true,
    })
    .then((response) => {
      if (response.data?.data.sessionToken) {
        let _expiry = "";
        if (!rememberMe) {
          _expiry = new Date().getTime() + 1000 * 60 * 30;
        }
        const userObj = {
          firstName: response.data?.data?.userInfo?.firstName,
          lastName: response.data?.data?.userInfo?.lastName,
          email: response.data?.data?.userInfo?.email,
          profileImage: response.data?.data?.userInfo?.profileImage,
          publicId: response.data?.data?.userInfo?.publicId,
        };


      }
      return response.data;
    });
};

const logout = () => {
  return axios
    .get(process.env.REACT_APP_API_URL + "user/logout", {}, {
      withCredentials: true,
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { login, logout };
